import { Box,  Text } from "@chakra-ui/react";
import {  Heading, Stack } from "@chakra-ui/react";

 function CardPortfolio({ title, subtitle }) {
    return (


        <Box height='fit-content' minWidth={'fit-content'}>
 
    
    <Stack mt='6' spacing='3'>
      <Heading size='md'>{title}</Heading>
      <Text textAlign={'justify'}>
       {subtitle}
      </Text>
   
    </Stack>


</Box>
    );
}

export default CardPortfolio;
