export default function AboutContentCa(){
    
    return (
       
            <p>

        De debò t'importa saber qui som? El que hauria d'importar-te és què podem fer per tu. Contacta'ns, obtingues el teu pressupost i fes realitat els teus dissenys.
        <br/>
        <br/>
Suposem que si de totes maneres, estàs interessat en saber una mica més sobre qui som, podem dir-te que som un servei creat per estudiants de disseny industrial. 
Estem interessats en el món de la impressió 3D (com és evident) i volem ajudar-te a dur a terme els teus projectes que requereixin d'aquest servei. 
En el passat hem tingut dificultats per trobar algú que ens oferís impressions en 3D a un preu assequible i amb rapidesa. 
Per a tots aquells que sempre treballeu a última hora, no us preocupeu que tindreu el vostre prototip a temps.
<br/>
<br/>
Ja està, s'ha acabat el "Qui som?" no perdis més el temps i corre a fer realitat els teus somnis🤙...  🙄
        
        
        </p>
     


    )

}



