import React from 'react';
import { Image } from "@chakra-ui/react";
import { Card, CardBody } from "@chakra-ui/react";

function CardPortfolio({ imageSrc }) {
  return (
    <Card maxW='sm' height={'500px'}>
      <CardBody>
        <Image
          src={imageSrc}
          maxWidth='100%'
          objectFit='cover'
          borderRadius='lg'
          width='100%'
          height='100%'
          maxW='400px'
        />
      </CardBody>
    </Card>
  );
}

export default CardPortfolio;
