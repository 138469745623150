

export default function AboutContentEn(){
    return (
        <div>
            <p>
            Do you really care who we are? What you should care about is what we can do for you. Contact us, get your quote and make your designs come true.
            <br/>
        <br/>
We guess if anyway, you are interested in knowing a little more about who we are, we can tell you that we are a service created by industrial design students. We are interested in the world of 3D printing (obviously) and we want to help you carry out your projects that require this service. In the past we've had a hard time getting someone to provide us with 3D printing affordably and quickly. For all those of you who always work at the last minute, don't worry that you will have your prototype on time.
<br/>
        <br/>
That's it, the "Who are we?" is over. don't waste any more time and run to make your dreams come true 🤙. 🙄
        </p>
        </div>


    )

}

