import React from 'react';
import '../css/Home.css';

import {

  Heading,
  Container,

} from '@chakra-ui/react';
import Slider from 'react-slick';

import CardPortfolio from './CardPortfolio';

import "react-multi-carousel/lib/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ChevronRightIcon,ChevronLeftIcon } from '@chakra-ui/icons';
//import portfolio from '../content/ca/portfolio.json';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import contentEn from '../content/en/portfolio.json'; // Import English content
import contentEs from '../content/es/portfolio.json'; // Import Spanish content
import contentCa from '../content/ca/portfolio.json'; // Import Catalan content

export default function Portfolio() {
  const { t, i18n } = useTranslation(); // Initialize the useTranslation hook

  let portfolio;
  // Determine which language is active
  switch (i18n.language) {
    case 'es':
      portfolio = contentEs;
      break;
    case 'ca':
      portfolio = contentCa;
      break;
    default:
      portfolio = contentEn; // English is the default language
      break;
  }
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  const requireImages = require.context('../images/portfolio', true, /\.(png|jpe?g|svg)$/);
  const images = requireImages.keys().map(requireImages);
;

  return (
    <Container
      maxWidth={'full'}
      
      height={'fit-content'}
      maxHeight={'full'}
      marginBottom={'200px'}
     
    >
        <Heading
                fontWeight='extrabold'
                fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                lineHeight={'110%'}
                dsffds={'erere'}
                textAlign={'center'}
                marginBottom={'100px'}
                >
                  
               {portfolio.title}      
          </Heading>


      <SimpleSlider images = {images} />
    </Container>
  );
}
const NextArrow = (props) => (
  <div onClick={props.onClick}>
    <ChevronRightIcon color="gray" boxSize={8} style={{ position: 'absolute', top: '50%', right: '-30px', transform: 'translateY(-50%)' , cursor: 'pointer', }} />
  </div>
);

const PrevArrow = (props) => (
  <div onClick={props.onClick}>
    <ChevronLeftIcon color="gray" boxSize={8}  style={{ position: 'absolute', top: '50%', left: '-30px', transform: 'translateY(-50%)' , cursor: 'pointer',}
  } />
  </div>
);
function SimpleSlider( {images}) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    vertical: false, // Set the carousel to be horizontal
    verticalSwiping: false, // Disable vertical swiping
    swipeToSlide: true, // Enable swipe to slide
    responsive: [
      {
        breakpoint:1700, // Large devices (desktops, less than 1200px)
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 1024, // Medium devices (tablets, small desktops)
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768, // Small devices (landscape phones)
        settings: {
          slidesToShow: 1,
        }
      }
    ],
    nextArrow: <NextArrow />, // Custom component for next arrow
    prevArrow: <PrevArrow />, // Custom component for previous arrow
  };
  
 

  return (
    <Slider {...settings}>
 {images.map((image, index) => (
    <div key={index}>
      <CardPortfolio imageSrc={image} />
    </div>
  ))}
    </Slider>
  );
}
