import React from 'react';
import '../css/Home.css'
import myimage3 from '../images/image3.jpg'
import { useState, useEffect } from 'react';
import Contact from './Contact.js';
import {
  Box,
  Heading,
  Container,
  Text,
  Stack,
  Image,
  Flex,
  Spacer

} from '@chakra-ui/react'
import AboutContentCa from '../content/ca/about_content';
import AboutContentEn from '../content/en/about_content.js';
import AboutContentEs from '../content/es/about_content.js';


import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import contentEn from '../content/en/about.json'; // Import English content
import contentEs from '../content/es/about.json'; // Import Spanish content
import contentCa from '../content/ca/about.json'; // Import Catalan content

export default function About() {
  const [shouldDisplayImage, setShouldDisplayImage] = useState(true);
  const { t, i18n } = useTranslation(); // Initialize the useTranslation hook

  let contentjson;
  let Content;
  // Determine which language is active
  switch (i18n.language) {
    case 'es':
      contentjson = contentEs;
      Content = AboutContentEs;
      break;
    case 'ca':
      contentjson = contentCa;
      Content = AboutContentCa;
      break;
    default:
      contentjson = contentEn; // English is the default language
      Content = AboutContentEn;
      break;
  }

  
  // Function to check the window width and set the display flag accordingly
  const handleWindowResize = () => {
    if (window.innerWidth <= 768) {
      setShouldDisplayImage(false);
    } else {
      setShouldDisplayImage(true);
    }
  };

  useEffect(() => {
    // Initial check on component mount
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      // Clean up the event listener on component unmount
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
        return (
        <Container maxWidth={'full'}  height={'fit-content'} maxHeight={'full'} 
        marginBottom={'200px'}>
             {/*Titol*/}
        <Heading
                fontWeight='extrabold'
                fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                lineHeight={'110%'}
               
                textAlign={'right'}
                >
                  
                {contentjson.title}           
          </Heading>
            <Stack
            direction={['column','row']}
         
          
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}>
             {  <Box width={shouldDisplayImage ? '30%' : '0%'}>
            {shouldDisplayImage &&<Flex direction={'column'}> <Image src={myimage3} borderTopLeftRadius={'10px'} sizes='100%' />

            {/*Imatges */}
           </Flex>}
            
              </Box>}
                <Box   width={shouldDisplayImage ? '70%' : '100%'}
                alignItems={'left'}
                textAlign={'left'}
                >
                  <Flex direction={'column'} mt={-10} alignItems={'left'} spacing = {'20px'} textAlign = 'left'>
            
          <Spacer/>

             {/*Text */}
          <Text
            textAlign="justify"
            fontSize={{ base: 'sm', sm: 'md', md: 'lg' }}
          >
           <Content/>
          </Text>  
          
        
          <Text color={'black'} marginTop={'50px'} textAlign={'center'}
          fontSize={{ base: '1xl', sm: '1xl', md: '3xl' }}>
                  {contentjson.contact}
                </Text>
           <Contact/>
            </Flex>
            </Box>
          
          

        </Stack>

        </Container>
      
        );
    

}


