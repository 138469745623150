

export default function ContentTextEs(){
    return (
<div>
Has llegado al final de la página. 

No hay nada más abajo.

Habiendo llegado a este punto, probablemente estás lo suficientemente interesado en hacer uso de una de las opciones que te ofrecemos:


</div>
    );
}

