import React from 'react';
import '../css/Home.css'


import {

  Heading,
  Container,


} from '@chakra-ui/react'
import { SimpleGrid } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import contentEn from '../content/en/faqs.json'; // Import English content
import contentEs from '../content/es/faqs.json'; // Import Spanish content
import contentCa from '../content/ca/faqs.json'; // Import Catalan content

import CardFAQ from './CardFAQ';
//import faqs from '../content/ca/faqs.json';
export default function FAQPage() {
  const { t, i18n } = useTranslation(); // Initialize the useTranslation hook

  let faqs;
  // Determine which language is active
  switch (i18n.language) {
    case 'es':
      faqs = contentEs;
      break;
    case 'ca':
      faqs = contentCa;
      break;
    default:
      faqs = contentEn; // English is the default language
      break;
  }
  return (
  <Container maxWidth={'full'} bgColor={'white'} height={'fit-content'} maxHeight={'full'} 
  marginBottom={'250px'}>
       {/*Titol*/}
  <Heading
          fontWeight='extrabold'
          fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
          lineHeight={'110%'}
        
          textAlign={'center'}
          >
            
      FAQ       
    </Heading>

    <SimpleGrid minChildWidth='200px' spacing='40px'>
    {faqs.faqs.map((faq) => (
        <CardFAQ title={faq.question} subtitle={faq.answer}/>
      ))
    }
      </SimpleGrid>
  </Container>

  );


}





