export default function LegalCa(){
    
    return (
       
            <p>
<br/>
<br/>
1. Dades identificatives
<br/>
Segons l’art. 10 de la Llei 34/2002, d’11 de Juliol, de Serveis de la Societat de la Informació i de Comerç Electrònic, el prestador de serveis i propietari del lloc web  www.thelab.cat és SATORRA FINTECH, SL, d’ara en endavant, el TITULAR, domiciliada a Barcelona, carrer Providència, 41, amb NIF B66650409. Les dades, la informació i continguts de l’esmentada pàgina web són propietat del titular. L’obtenció, reproducció, distribució, cessió, transmissió, transformació, modificació, explotació o comunicació pública per a un ús diferent del que s’ha previst en la web queda expressament prohibit.
<br/>
2. Condicions d’accés, utilització i funcionament del lloc web.
<br/>
El lloc web és de lliure accés. Queda expressament prohibit que se’n faci un ús que provoqui danys o alteracions dels continguts del lloc web, entre d’altres i no exhaustivament, intervenir o modificar el correu electrònic.  El lloc web no disposa d’usuaris.
<br/>
3. Enllaços
<br/>
En el supòsit que el lloc web disposés d’enllaços a altres llocs webs, el titular no exercirà cap tipus de control sobre aquests altres llocs ni respecte el contingut dels mateix. En cap cas, el titular assumirà cap responsabilitat pels continguts enllaçats a un lloc web extern, ni garantirà la disponibilitat tècnica, la qualitat, exactitud, veracitat o validesa de qualsevol material o informació que estigui publicada en aquests llocs webs externs. 
<br/>
4. Propietat industrial i intel·lectual
<br/>
Tots els continguts del lloc web són propietat del titular del mateix o li han estat cedides al titular per al seu ús. 
<br/>
5. legislació i jurisdicció aplicable.
<br/>
Qualsevol controvèrsia que pogués sorgir en relació a aquest lloc web es resoldrà d’acord amb la legislació espanyola i es sotmetrà als Jutjats i Tribunals de la ciutat de Barcelona.
<br/>


Informació relativa al ús de galetes
<br/>
El lloc web utilitza galetes per a fer servir l’idioma de preferència de l’usuari. No s’emmagatzemen en l’ordinador de l’usuari que fa ús del lloc web altres galetes. L’usuari pot negar-se a l’ús de galetes.
Segons les directives europees sobre protecció de dades, els navegadors web disposen de la configuració necessària per a eliminar o desactivar les galetes.
<br/>
La nostra adreça de correu electrònic és info@thelab.cat per a poder contactar amb nosaltres respecte la política de galetes.
<br/>
        
        </p>
     


    )

}



