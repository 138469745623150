import { useState } from 'react';
import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image
} from '@chakra-ui/react';
import { GrLanguage } from 'react-icons/gr';
import { useTranslation } from 'react-i18next';
import i18n from './i18n';
import logo from '../images/logo.jpg'
import Cookies from 'js-cookie';
const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    Cookies.set('userLanguage', lng, { expires: 365 }); // Store for 365 days
 
  };

  return (
    <Flex alignItems="center">
      <Menu>
        <MenuButton as={IconButton} icon={<LanguageIcon />} variant="ghost" />
        <MenuList>
          <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
          <MenuItem onClick={() => changeLanguage('es')}>Castellano</MenuItem>
          <MenuItem onClick={() => changeLanguage('ca')}>Català</MenuItem>
          {/* Add more MenuItem components for other languages */}
        </MenuList>
      </Menu>
    </Flex>
  );
};

const LanguageIcon = () => {
  // This is a placeholder icon
  return <span><GrLanguage/></span>;
};

const Navbar = () => {
    return (
      <Box as="nav" p={4} color="black">
        <Flex justify="space-between" alignItems="center" marginBottom="20px">
          <Box flex="1" textAlign="center"> {/* To position the image in the middle */}
            <Image
              src={logo}
              alt="logo"
              w="100%"
              maxW="50px"
              h="auto"
              borderRadius="full"
            />
          </Box>
          <Box>
            <LanguageSelector />
          </Box>
        </Flex>
        <hr />
      </Box>
    );
  };
  
export default Navbar;
