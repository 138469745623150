import React from 'react';
import '../css/Home.css'
import { HashLink as Link } from 'react-router-hash-link';
import { useState, useEffect } from 'react';
import Contact from './Contact';
import {
  Box,
  Heading,
  Container,
  Text,
  Button,
  Stack,
  Flex,
  Spacer

} from '@chakra-ui/react'

//import contentJson from '../content/ca/home.json';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import contentEn from '../content/en/home.json'; // Import English content
import contentEs from '../content/es/home.json'; // Import Spanish content
import contentCa from '../content/ca/home.json'; // Import Catalan content

export default function Home() {
  const [shouldDisplayImage, setShouldDisplayImage] = useState(true);
  const { t, i18n } = useTranslation(); // Initialize the useTranslation hook
  let contentJson;
  
  // Determine which language is active
  switch (i18n.language) {
    case 'es':
      contentJson = contentEs;
      break;
    case 'ca':
      contentJson = contentCa;
      break;
    default:
      contentJson = contentEn; // English is the default language
      break;
  }

  // Function to check the window width and set the display flag accordingly
  const handleWindowResize = () => {
    if (window.innerWidth <= 768) {
      setShouldDisplayImage(false);
    } else {
      setShouldDisplayImage(true);
    }
  };

  useEffect(() => {
    // Initial check on component mount
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      // Clean up the event listener on component unmount
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
        return (
        <Container maxWidth={'100%'} height={'fit-content'} maxHeight={'full'} width={'100%'} margin={'0px'}
        marginBottom={'200px'} >
      
            <Stack
            direction={['column','row']}
         
          
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}>
            <Box   width={shouldDisplayImage ? '100%' : '100%'}
            alignItems={'center'}
            textAlign={'center'}
            paddingTop={'50px'}>
              <Flex direction={'column'} mt={-10} alignItems={'center'} spacing = {'20px'}>
          <Heading
            fontWeight='extrabold'
            textAlign={'center'}
            
            fontSize={{ base: '4xl', sm: '6xl', md: '8xl' }}
            lineHeight={'110%'}>
              <Text
              fontWeight='extrabold'
              bgColor = '#d00000'
              
              bgClip={'text'}
            >
              {contentJson.title}
              </Text>
             <br />
            
          </Heading>

          <Text color={'black'}
          textAlign={'center'}
          fontWeight={'bold'}
          fontSize={{ base: '1xl', sm: '2xl', md: '4xl' }}>

            {contentJson.subtitles.map((subtitle, index) => (
          <p key={index}>
            {subtitle}
            <br />
          </p>
        ))}
          </Text>
          <Spacer/>
          <Text color={'black'} marginTop={'50px'} textAlign={'center'}
          fontSize={{ base: '1xl', sm: '1xl', md: '3xl' }}>
                 {contentJson.contact}
                </Text>
          <Contact/>
        
          <Box
            display={'inline-block'}
            mt={8}
            marginBottom = {'20px'}
            >
            <Button
              colorScheme={'gray'}
              bg={'gray.200'}
              rounded={'full'}
              px={6}
              onClick={() => {window.scrollTo({top: document.getElementById('calculator').offsetTop, behavior: 'smooth'});}}

              _hover={{
                bg: 'gray.300',
              }}>
              {contentJson.calculator}
            </Button>
            
            <Spacer/>
            
            </Box>
            
            
            </Flex>
            </Box>
            {/*
            <Box width={shouldDisplayImage ? '60%' : '0%'}>
            {shouldDisplayImage && <Image src={myimage} borderTopRightRadius={'10px'} sizes='100%' />}
            
              </Box>*/}

        </Stack>

        </Container>
      
        );
    

}


