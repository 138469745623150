import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import Cookies from 'js-cookie';

const userPreferredLanguage = navigator.language || navigator.userLanguage || 'en';
const storedLanguage = Cookies.get('userLanguage');
const selectedLanguage = storedLanguage || userPreferredLanguage;
console.log('Selected Language:', selectedLanguage);
// Set the language cookie console.log('Selected Language:', selectedLanguage);before initializing i18n
Cookies.set('userLanguage', selectedLanguage, { expires: 365 }); // Store for 365 days

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: selectedLanguage, // Set the language based on user preferences

    supportedLngs: ['en', 'es', 'ca'], // English, Spanish, and Catalan
    fallbackLng: 'en', // Default language
    backend: {
      loadPath: '/locales/{{lng}}/translation.json', // Path to translation files
    },
    interpolation: {
      escapeValue: false,
    },
  });
 
export default i18n;
