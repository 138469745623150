export default function LegalEs(){
    
    return (
       
            <p>
<br/>
1. Datos identificativos.
<br/>
Según el art. 10 de la Ley 34/2002, de 11 de Julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico, el prestador de servicios y propietario del sitio web www.thelab.cat es SATORRA FINTECH, SL, de ahora en adelante, el TITULAR, domiciliada en Barcelona, calle Providencia, 41, con NIF B66650409. Los datos, información y contenidos de la citada página web son propiedad del titular. La obtención, reproducción, distribución, cesión, transmisión, transformación, modificación, explotación o comunicación pública para un uso distinto al previsto en la web queda expresamente prohibido.
<br/>
2. Condiciones de acceso, utilización y funcionamiento del sitio web.
<br/>
El sitio web es de libre acceso. Queda expresamente prohibido que se haga un uso que provoque daños o alteraciones de los contenidos del sitio web, entre otros y no exhaustivamente, intervenir o modificar el correo electrónico. El sitio web no dispone de usuarios.
<br/>
3. Enlaces
<br/>
En el supuesto de que el sitio web dispusiera de enlaces a otros sitios webs, el titular no ejercerá ningún tipo de control sobre estos otros sitios ni respecto al contenido de los mismos. En ningún caso, el titular asumirá responsabilidad alguna por los contenidos enlazados a un sitio web externo, ni garantizará la disponibilidad técnica, la calidad, exactitud, veracidad o validez de cualquier material o información que esté publicada en estos sitios webs externos.
<br/>
4. Propiedad industrial y intelectual
<br/>
Todos los contenidos del sitio web son propiedad del titular del mismo o le han sido cedidas al titular para su uso.
<br/>
5. Legislación y jurisdicción aplicable.
<br/>
Cualquier controversia que pudiera surgir en relación con este sitio web se resolverá de acuerdo con la legislación española y se someterá a los Juzgados y Tribunales de la ciudad de Barcelona.
<br/>
Información relativa al uso de cookies
<br/>
El sitio web utiliza cookies para utilizar el idioma de preferencia del usuario. No se almacenan en el ordenador del usuario que hace uso del sitio web otras cookies. El usuario puede negarse al uso de cookies. Según las directivas europeas sobre protección de datos, los navegadores web disponen de la configuración necesaria para eliminar o desactivar las cookies.
<br/>
Nuestra dirección de correo electrónico es info@thelab.cat para poder contactar con nosotros respecto a la política de cookies.
<br/>
        
        </p>
     


    )

}



