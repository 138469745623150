import React from 'react';
import "../css/Calculator.css";
import { Box, Spacer, Heading,  CardHeader } from "@chakra-ui/react";
import Calculator from './Calculator';
import { Container } from '@chakra-ui/react';
import { Card } from '@chakra-ui/react';
import contentEn from '../content/en/calculator.json'; // Import English content
import contentEs from '../content/es/calculator.json'; // Import Spanish content
import contentCa from '../content/ca/calculator.json'; // Import Catalan content
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook

export default function CalculatorSection (){
    const { t, i18n } = useTranslation(); // Initialize the useTranslation hook

    let contentJson;
    // Determine which language is active
    switch (i18n.language) {
      case 'es':
        contentJson = contentEs;
        break;
      case 'ca':
        contentJson = contentCa;
        break;
      default:
        contentJson = contentEn; // English is the default language
        break;
    }
    return(

        <Container maxWidth={'full'} bgColor={'white'} height={'fit-content'}
        marginBottom={'200px'} alignContent={'center'} justifyContent={'center'} paddingTop={'50px'}  minHeight={'650px'} >
           <Spacer />
        <Card width= {'96%'} mx="auto">
            <CardHeader>
            <Heading size='md'>{contentJson.title}</Heading>
            </CardHeader>
            <Box  minHeight={'400px'} height={'100%'} verticalAlign={'center'} width={'100%'} >
                <Calculator/>
            </Box>

        </Card>
        </Container>



    )



}