
export default function ContentTextCa(){
    return (
<div>
Has arribat al final de la pàgina. 
<br/>
Més avall no queda res.
<br/>
Arribats a aquest punt...<br/><br/> ...deus estar prou interessat per fer ús<br/> d'alguna de les opcions que t'oferim:
</div>
    );
}

