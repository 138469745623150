import React, { useState, useEffect } from "react";
import Model from "./STLModel";
import { Box, Flex, Center, Stack,Spacer , Button} from "@chakra-ui/react";
import { Input } from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import * as THREE from "three";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import "../css/Calculator.css";
import { Text, useColorModeValue } from "@chakra-ui/react";
import { IconButton } from "@chakra-ui/react";
import { FaWhatsapp, FaInstagram, FaEnvelope } from "react-icons/fa";
import { Link } from "@chakra-ui/react";
import { calculatePrice } from "../utils/utils.js";
//simport contentJson from '../content/ca/calculator.json';
//import { STLLoader } from 'three-stl-loader';
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Container,
} from "@chakra-ui/react";
import { Select } from "@chakra-ui/react";
import Contact from "./Contact";
import contentEn from '../content/en/calculator.json'; // Import English content
import contentEs from '../content/es/calculator.json'; // Import Spanish content
import contentCa from '../content/ca/calculator.json'; // Import Catalan content
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook

//const url = ""
/*const url =
  "https://storage.googleapis.com/ucloud-v3/ccab50f18fb14c91ccca300a.stl";*/
//const curaApiUrl = "https://api.ultimaker.com/cura/v1"

//const url = "https://storage.googleapis.com/ucloud-v3/ccab50f18fb14c91ccca300a.stl"


function Calculator() {
  const [file, setFile] = useState(null);
  const [stlUrl, setStlUrl] = useState(null);
  const [modelSize, setModelSize] = useState(null);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [z, setZ] = useState(0);
  const [totalVolume, setTotalVolume] = useState(null);
  const [defaultVolume, setDefaultVolume] = useState(null);
  const [scale, setScale] = useState(100);
  const [price, setPrice] = useState(null);
  const [material, setMaterial] = useState('PLA_BASIC'); // Set the default material to PLA_BASIC
  const density = 1.2; // kg/m³
  const [shouldDisplayImage, setShouldDisplayImage] = useState(true);
  // Function to check the window width and set the display flag accordingly
  const materials = ["PLA_BASIC", "ABS", "Nylon CF", "PLA_SILK", "PC", "TPU", "PETG"];
  const { t, i18n } = useTranslation(); // Initialize the useTranslation hook

  let contentJson;
  // Determine which language is active
  switch (i18n.language) {
    case 'es':
      contentJson = contentEs;
      break;
    case 'ca':
      contentJson = contentCa;
      break;
    default:
      contentJson = contentEn; // English is the default language
      break;
  }
  const handleWindowResize = () => {
    if (window.innerWidth <= 1200) {
      setShouldDisplayImage(false);
    } else {
      setShouldDisplayImage(true);
    }
  };

  useEffect(() => {
    // Initial check on component mount
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      // Clean up the event listener on component unmount
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (stlUrl && file) {    
      handleCalculateModelData();
      calculateModelSize(stlUrl);

  
    }
  }, [stlUrl, file]);
  

  useEffect(() => {
    if (modelSize) {
      setX(modelSize.x.toFixed(2));
      setY(modelSize.y.toFixed(2));
      setZ(modelSize.z.toFixed(2));
    }
  }, [modelSize]);
  const handleScaleChange = (value) => {
    const newScale = parseFloat(value);
    setScale(newScale); // Convert to percentage

    setX((modelSize.x * newScale * 0.01).toFixed(2));
    setY((modelSize.y * newScale * 0.01).toFixed(2));
    setZ((modelSize.z * newScale * 0.01).toFixed(2));

    const newVolume = calculateScaledVolume(newScale * 0.01);
    setTotalVolume(newVolume);

    const newPrice = calculatePrice(newVolume / 1000, material); // Recalculate price based on scale change
    setPrice(newPrice);
  };
  function calculateScaledVolume(newScale) {
    // Calculate the scaled volume based on the new scale
    const scaledVolume = Math.pow(newScale, 3) * defaultVolume;
    return scaledVolume;
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const newSize = parseFloat(value);
    const newScale = newSize / modelSize[name];
    setScale(newScale);
    if (name === "x") {
      setX(newSize.toFixed(2));
      setY((modelSize.y * newScale).toFixed(2));
      setZ((modelSize.z * newScale).toFixed(2));
    } else if (name === "y") {
      setX((modelSize.x * newScale).toFixed(2));
      setY(newSize.toFixed(2));
      setZ((modelSize.z * newScale).toFixed(2));
    } else if (name === "z") {
      setX((modelSize.x * newScale).toFixed(2));
      setY((modelSize.y * newScale).toFixed(2));
      setZ(newSize.toFixed(2));
    }
  };
  const handleMaterialChange = (event) => {
    const selectedMaterial = event.target.value;
    setMaterial(selectedMaterial);
    const newPrice = calculatePrice(totalVolume/1000, selectedMaterial);
    setPrice(newPrice);
  }
  function calculateModelSize(stlUrl) {
    const loader = new STLLoader();

    loader.load(stlUrl, (geometry) => {
      const boundingBox = new THREE.Box3().setFromObject(
        new THREE.Mesh(geometry)
      );
      const size = boundingBox.getSize(new THREE.Vector3());
      setModelSize(size);
    });
  }

  // This is the function to calculate the signed volume of a triangle
  function signedVolumeOfTriangle(p1, p2, p3) {
    const v321 = p3.x * p2.y * p1.z;
    const v231 = p2.x * p3.y * p1.z;
    const v312 = p3.x * p1.y * p2.z;
    const v132 = p1.x * p3.y * p2.z;
    const v213 = p2.x * p1.y * p3.z;
    const v123 = p1.x * p2.y * p3.z;
    return (-v321 + v231 + v312 - v132 - v213 + v123) / 6;
  }

  function calculateTotalVolume(triangles) {
    let totalVolume = 0;

    for (const triangle of triangles) {
      totalVolume += signedVolumeOfTriangle(
        triangle[0],
        triangle[1],
        triangle[2]
      );
    }

    // Convert to a positive value
    totalVolume = Math.abs(totalVolume);

    // The result is in cubic units (e.g., mm^3). You can convert it to other units if needed.

    return totalVolume;
  }

  function handleCalculateModelData () {
    if (file) {
      // Call the STL file parsing and volume calculation functions
      const loader = new STLLoader();
      loader.load(stlUrl, (geometry) => {
        const vertices = geometry.attributes.position.array;
        const triangles = [];
        for (let i = 0; i < vertices.length; i += 9) {
          triangles.push([
            { x: vertices[i], y: vertices[i + 1], z: vertices[i + 2] },
            { x: vertices[i + 3], y: vertices[i + 4], z: vertices[i + 5] },
            { x: vertices[i + 6], y: vertices[i + 7], z: vertices[i + 8] },
          ]);
        }

        // Calculate the total volume
        const totalVolume = calculateTotalVolume(triangles);
        const defaultVolume = totalVolume;
        // Display the calculated volume or use it as needed
        setTotalVolume(totalVolume);
        setDefaultVolume(defaultVolume); // You can update state or display the volume in your component
        const estimatedWeight = (totalVolume / 1000) * density;

      // Calculate the price (assuming 20€ per gram)
      const newPrice = calculatePrice(totalVolume / 1000, material); // Assuming 'calculatePrice' calculates the price based on material and volume

      // Set the price in the state or use it in your component
      setPrice(newPrice);
      });
    }
  };



  function Dropzone() {
    const [isDragging, setIsDragging] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
  
    const { getRootProps, getInputProps } = useDropzone({
      onDragEnter: () => {
        setIsDragging(true);
      },
      onDragLeave: () => {
        setIsDragging(false);
      },
      onDrop: (acceptedFiles, rejectedFiles) => {
        if (rejectedFiles && rejectedFiles.length > 0) {
          const unsupportedFile = rejectedFiles[0];
          setErrorMessage(`Unsupported file type: ${unsupportedFile.type}. Please drop a .STL file.`);
        } else {
          setErrorMessage('');
          const uploadedFile = acceptedFiles[0];
          setFile(uploadedFile);
          const fileExtension = uploadedFile.name.split('.').pop().toLowerCase();
          if (fileExtension !== 'stl') {
            setErrorMessage(contentJson.dropzone.error);
            setFile(null);
          } else {
            const fileUrl = URL.createObjectURL(uploadedFile);
            setStlUrl(fileUrl);
          }
        }
      },
      accept: ".stl",
    });
  
    const borderColor = useColorModeValue("gray.300", "gray.600");
    const backgroundColor = isDragging ? "gray.100" : "white";
    const cursor = isDragging ? "grabbing" : "pointer";
  
    return (
      <Box
        borderWidth="1px"
        borderStyle="dashed"
        borderColor={borderColor}
        borderRadius="md"
        marginTop="20px"
        height={"95%"}
        p={4}
        m={2}
        cursor={cursor}
        backgroundColor={backgroundColor}
        _hover={{ backgroundColor: useColorModeValue("gray.100", "gray.700") }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Center height={"100%"} maxHeight={"full"}  minHeight={"300px"} width={"100%"}>
          {errorMessage ? (
            <Text color="red.500">{errorMessage}</Text>
          ) : isDragging ? (
            <Text>{contentJson.dropzone.dragging}</Text>
          ) : (
            <Text>{contentJson.dropzone.default}</Text>
          )}
        </Center>
      </Box>
    );
  }


  return (
    <Container maxWidth={"full"} height={"100%"} maxHeight={"full"}  minHeight={'400px'}  verticalAlign={'center'} width={'100%'} 
    >
      {file ? (
        <Stack
          direction={shouldDisplayImage? 'row' : 'column'}
          spacing={{ base: 8, md: 14 }}
          height={"100%"}
          minHeight={"400px"}
          paddingBottom={"20px"}
        >
          <Box height={"100%"} minHeight={'400px'} width={shouldDisplayImage ? '66.67%' : '100%'}>
            <Flex flexDirection="column" minHeight={'400px'} height={"100%"} alignItems="center">
              <Box border="1px" borderColor="gray.300" borderRadius="md" width="100%" height="100%" marginBottom={'10px'} >
              <Model url={stlUrl} />
                </Box>
                <Spacer/>
                <Box>
              <p>
                {contentJson.body.volume_label}:{" "}
                {totalVolume !== null
                  ? `${(totalVolume / 1000).toFixed(2)} cm³`
                  : "Calculating..."}
              </p>
{/*<p>
                Estimated weight:{" "}
                {totalVolume !== null
                  ? `${((totalVolume / 1000) * density).toFixed(2)} g`
                  : "Calculating..."}
              </p> */}
              
              </Box>
            </Flex>
          </Box>
          {/*File info*/}
          <Flex flexDirection={"Column"}  height={"400px"} minWidth={'fit-content'}width={shouldDisplayImage ? '33.33%' : '100%'}
           alignItems={'center'}
           textAlign={'center'} spacing = '10px'>
            <p style={{ textAlign: "center" }}>{contentJson.body.scale_label}: {scale.toFixed(2)}%</p>
            
              <Slider
                value={scale}
                min={10}
                max={500}
                step={10}
                onChange={handleScaleChange}
              >
                <SliderTrack bg="red.100">
                  <Box position="relative" right={10} />
                  <SliderFilledTrack bg="tomato" />
                </SliderTrack>
                <SliderThumb boxSize={6} />
              </Slider>
              <Spacer />
              {contentJson.body.material_label}
              <Select  onChange={handleMaterialChange}
              
              value = {material}>
                {materials.map((material) => (
                  <option key={material} value={material}>
                    {material}
                  </option>
                ))}
              </Select>
           
            
              <Spacer />
              {contentJson.body.size_label}
              <Flex flexDirection={"Row"}>
                <Input
                  name="x"
                  htmlSize={4}
                  width="auto"
                  value={(x * 0.1).toFixed(2)}
                  onChange={handleInputChange}
                />
                <Spacer />
                x
                <Spacer />
                <Input
                  name="y"
                  htmlSize={4}
                  width="auto"
                  value={(y * 0.1).toFixed(2)}
                  onChange={handleInputChange}
                />
               <Spacer /> x <Spacer />
                <Input
                  name="z"
                  htmlSize={4}
                  width="auto"
                  value={(z * 0.1).toFixed(2)}
                  onChange={handleInputChange}
                />
              </Flex>
              <Spacer />
              {contentJson.body.price_label}:{" "}
  {price !== null
    ? `${(price).toFixed(2)}€`
    : "Calculating..."}
      <Text fontSize="xs" color="red">
                  {contentJson.note}
                </Text>
              <Spacer />
              <Box alignItems={'center'} alignContent={'center'}>
          <Text color={'black'} marginTop={'50px'}
          fontSize={ '2xl' }>
                  {contentJson.contact}
                </Text>
              
          <Contact/>
          </Box>
          
            </Flex>
          
        </Stack>
      ) : (
        <Dropzone />
      )}
    </Container>
  );
}

export default Calculator;
