// components/STLViewer.js
import React from 'react';

import {StlViewer} from "react-stl-viewer";
export default function Model ({url})  {
    const style = {
        top: 0,
        left: 0,
        minWidth: '100px', 
        minHeight:'400px',
    width: '100%',
  height: '250px'
   
      }
   

    return (
        <StlViewer
        style={style}
        orbitControls
        shadows
        showAxes
        url={url}
        
    />

    );
};
