
export default function AboutContentEs(){
    return (
        <div>
            <p>
            ¿De verdad te importa saber quiénes somos? Lo que debería importarte es qué podemos hacer por ti. Contáctanos, obtén tu presupuesto y haz realidad tus diseños.
            <br/>
            <br/>
Suponemos que si de todas formas, estás interesado en saber un poco más sobre quiénes somos, podemos decirte que somos un serverio creado por estudiantes de diseño industrial . Estamos interesados en el mundo de la impresión 3D (como es evidente) y queremos ayudarte a llevar a cabo tus proyectos que requieran este servicio. En el pasado hemos tenido dificultades para obtener a alguien que nos ofrezca impresiones en 3D a un precio asequible y con rapidez. Para todos aquellos que siempre trabaja a última hora, no se preocupe que tendrá su prototipo a tiempo.
            <br/>
Ya está, se acabó el "¿Quiénes somos?" no pierdas más el tiempo y corre a hacer realidad tus sueños 🤙. 🙄

        </p>
        </div>


    )

}

