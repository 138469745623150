import React from "react";
import { Button, Flex } from "@chakra-ui/react";
import { ArrowUpIcon } from "@chakra-ui/icons";

const FloatingGoHome = () => {
  const style = {
    position: "fixed",
    bottom: 30, // Set the bottom position dynamically
    right: 4,
    zIndex: 999,
    justify: "flex-end"
  };
  const scrollToHome = () => {
    const homeSection = document.getElementById('home');
    if (homeSection) {
      window.scrollTo({ top: homeSection.offsetTop, behavior: 'smooth' });
    }
  };


  return (
    <Flex style={style}>
        <Button onClick={scrollToHome}>       
         <ArrowUpIcon />
      </Button>
    </Flex>
  );
};

export default FloatingGoHome;
