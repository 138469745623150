export default function PrivacyEs(){
    
    return (
       
            <p>

        <br/>
<b>Quiénes somos</b>
    <br/>
Nuestra dirección web es www.thelab.cat. El responsable del tratamiento de los datos de este sitio web es SATORRA FINTECH, SL, info@thelab.cat, domiciliada en Barcelona, calle Providència, 41. NIF B66650409.
<br/>
Los datos personales se han obtenido directamente del titular de los mismos o de su representante legal.
<br/>
Datos identificativos, Nombre y apellidos, Razo Social, NIF/CIF.
    <br/>
Domicilio de envío
    <br/>
Correo electrónico
    <br/>
Datos bancarios.
<br/>
<b>Finalidad del tratamiento de datos</b>
    <br/>
Sus datos serán tratados para responder a su solicitud de información, cuestiones o dudas que tenga sobre nuestros servicios. En ningún caso, sus datos serán cedidos a terceros.
    <br/>
<b>¿Cuánto tiempo retenemos los datos</b>
    <br/>
Sus datos personales se conservarán mientras no solicite la supresión de ellos. En caso de que lo haga, se suprimirán.
Puede solicitar la supresión de sus datos en cualquier momento mediante solicitud a través de la dirección de correo electrónico info@thelab.cat.
    <br/>
<b>¿Cuáles son sus derechos?</b>
    <br/>
En cualquier momento puede solicitar y obtener información sobre la manera y la finalidad con que tratamos sus datos.
    <br/>
Puede ejercer los siguientes derechos:
<br/>
Acceder a sus datos: solicitar qué datos suyos tenemos y qué uso hacemos e, incluso, pedir que se los entreguemos para que los pueda enviar a otro responsable.
    <br/>
Rectificar sus datos: solicitar la modificación o rectificación de datos inexactos.
    <br/>
Suprimir sus datos: solicitar que sus datos sean eliminados y no puedan ser tratados, fuera de lo que la legislación obliga a conservar.
    <br/>
Oponerse a un determinado tratamiento de sus datos: solicitar que no hagamos un uso concreto de sus datos.
    <br/>
Con esta finalidad, nos puede escribir indicándonos qué derecho desea ejercer (acceso, rectificación, supresión, oposición o portabilidad de los datos), adjuntando una copia de su DNI, a:
SATORRA FINTECH SL calle Providencia, 41 08024 Barcelona o bien en la dirección de correo electrónico datos@thelab.cat
    <br/>
<b>Cookies</b>
    <br/>
Las cookies son archivos que quedan descargados en el ordenador del usuario que ha accedido al sitio web. Estos archivos contienen información sobre el hábito de navegación o preferencias determinadas del usuario o de su equipo informático. Las cookies se asocian a un usuario anónimo y su equipo, y la información recogida no permite identificar los datos personales.
<br/>
Cookies propias utilizadas en este sitio web
    <br/>
Cookies técnicas
    <br/>
Las cookies técnicas son esenciales y necesarias para que la web funcione correctamente y para utilizar las diversas opciones y servicios que ofrece.
    <br/>
Puede modificar la configuración del navegador web para que rechace nuevas cookies, desactive las que ya existen o que le comunique cuando alguien le envía una nueva cookie a su dispositivo. Según el navegador que utilice:
internetExplorer = 'Internet Explorer: Herramientas > Opciones de internet > Privacidad'
    <br/>
firefox = 'Firefox: Herramientas > Opciones > Privacidad > Historial > Parámetros personalizados.'
    <br/>
chrome = 'Chrome: Configuración > Muestra la configuración avanzada > Privacidad > Configuración del contenido.'
    <br/>
safari = 'Safari: Preferencias > Seguridad.'
        <br/>
Bloquear el uso de las cookies en su navegación puede hacer que algunos servicios o características del sitio web no estén disponibles.
        <br/>  
Contenido incrustado de otros sitios web
        <br/>
Los artículos en esta página web incluyen contenido incrustado (por ejemplo, vídeos, imágenes, artículos, etc.). El contenido incrustado desde otras páginas web se comporta exactamente de la misma forma como si el visitante estuviera visitando la otra página web.
        <br/>
Estas páginas web pueden recoger datos sobre usted, utilizar cookies, incrustar seguimiento adicional de terceras partes, y monitorizar la interacción con el contenido incrustado, incluyendo la traza de la interacción con el contenido incrustado si tiene una cuenta y ha iniciado sesión en esta página web.      
        <br/>
        </p>
     


    )

}



