export default function PrivacyCa(){
    
    return (
       
            <p>

    <br/>
<b>Qui som</b>
    <br/>
La nostra adreça web és www.thelab.cat. El responsable del tractament de les dades d'aquest lloc web és SATORRA FINTECH, SL, info@thelab.cat, domiciliada a Barcelona, carrer Providència, 41. NIF B66650409.
        <br/>
Les dades personals s’han obtingut directament del titular de les mateixes o del seu representant legal. 
<br/>
Dades identificatives, Nom i cognoms, Rao Social, NIF/CIF.
        <br/>
Domicili d’enviament
    <br/>
Correu electrònic
    <br/>
Dades bancàries.
<br/>
<b>Finalitat del tractament de dades </b>
    <br/>
Les vostres dades seran tractades per tal de respondre a la vostra sol·licitud d'informació, qüestions o dubtes que tingueu sobre els nostres serveis. En cap cas, les vostres dades seran cedides a tercers.
        <br/>
<b>Quant de temps retenim les dades</b>
    <br/>
Les vostres dades personals es conservaran mentre no sol·liciteu la supressió d'elles vostres dades. En el cas que ho feu, se suprimiran.
Podeu sol·licitar la supressió de les vostres dades en qualsevol moment mitjançant sol·licitud a través de la direcció de correu electrònic info@thelab.cat.
    <br/>
<b>Quins són els vostres drets </b>
    <br/>
En qualsevol moment podeu sol·licitar i obtenir informació sobre la manera i la finalitat amb les quals tractem les vostres dades.
    <br/>
Podeu exercir els drets següents:
<br/>
Accedir a les vostres dades: sol·licitar quines dades vostres tenim i quin ús en fem i, fins i tot, demanar que us les lliurem perquè les pugueu trametre a un altre responsable.
    <br/>
Rectificar les vostres dades: sol·licitar la modificació o rectificació de dades inexactes.
    <br/>
Suprimir les vostres dades: sol·licitar que les vostres dades siguin eliminades i no puguin ser tractades, fora del que la legislació obliga a conservar.
    <br/>
Oposar-vos a un determinat tractament de les vostres dades: sol·licitar que no fem un ús concret de les vostres dades.
    <br/>
Amb aquesta finalitat, ens podeu escriure indicant-nos quin dret voleu exercir (accés, rectificació, supressió, oposició o portabilitat de les dades), adjuntant una còpia del vostre DNI, a:
    <br/>
SATORRA FINTECH SL carrer Providència, 41 08024 Barcelona o bé a la direcció de correu electrònic dades@thelab.cat
        <br/>
<b>Galetes</b>
    <br/>
Les galetes són fitxers que queden descarregats en l'ordinador de l'usuari que ha accedit al lloc web. Aquests fitxers contenen informació sobre l'hàbit de navegació o preferències determinades de l'usuari o del seu equip informàtic. Les galetes s'associen a un usuari anònim i al seu equip, i la informació recollida no permet identificar les dades personals.
        <br/>
Cookies pròpies utilitzades en aquest lloc web
    <br/>
Cookies tècniques
    <br/>
Les cookies tècniques són essencials i necessàries perquè el web funcioni correctament i per usar les diverses opcions i serveis que ofereix.
        <br/>
Configuració dels navegadors
    <br/>
Podeu modificar la configuració del navegador web perquè rebutgi noves galetes, desactivi les que ja existeixen o bé que us comuniqui quan algú us envia una nova galeta al vostre dispositiu. Segons el navegador que feu servir:
    <br/>
const internetExplorer = 'Internet Explorer: Eines > Opcions d\'internet > Privadesa';
    <br/>
const firefox = 'Firefox: Eines > Opcions > Privadesa > Historial > Paràmetres personalitzats.';
    <br/>
const chrome = 'Chrome: Configuració > Mostra la configuració avançada > Privadesa > Configuració del contingut.';
    <br/>
const safari = 'Safari: Preferències > Seguretat.';
    <br/>     
Bloquejar l’ús de les cookies en la vostra navegació pot fer que alguns serveis o característiques del lloc web no estiguin disponibles.
        <br/>
        <br/>
Els articles en aquesta pàgina web inclou contingut incrustat (per exemple, vídeos, imatges, articles, etc.). El contingut incrustat des d\'altres pàgines web es comporta exactament de la mateixa manera com si el visitant estigués visitant l\'altra pàgina web.
        <br/>
Aquestes pàgines web poden recollir dades sobre vós, fer servir galetes, incrustar seguiment addicional de terceres parts, i monitorar la interacció amb el contingut incrustat, incloent-hi la traça de la interacció amb el contingut incrustat si teniu un compte i heu iniciat sessió en aquesta pàgina web.
       </p>

    )

}



