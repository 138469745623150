import React, { useState } from 'react';
import { Flex, Box, Spacer, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Image } from '@chakra-ui/react';
import logo from '../images/logo.jpg';
import contentEn from '../content/en/footer.json'; // Import English content
import contentEs from '../content/es/footer.json'; // Import Spanish content
import contentCa from '../content/ca/footer.json'; // Import Catalan content

import LegalCa from '../content/ca/legal.js';
import LegalEs from '../content/es/legal.js';
import LegalEn from '../content/en/legal.js';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook

import PrivacyCa from '../content/ca/privacy.js';
import PrivacyEs from '../content/es/privacy.js';
import PrivacyEn from '../content/en/privacy.js';

export default function Footer() {
    const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
    const [legalModalOpen, setLegalModalOpen] = useState(false);
    const { t, i18n } = useTranslation(); // Initialize the useTranslation hook

    let LegalText;
    let PrivacyText;
    let contentJson;
    // Determine which language is active
    switch (i18n.language) {
      case 'es':
        contentJson = contentEs;
        LegalText = LegalEs;
        PrivacyText = PrivacyEs;

        break;
      case 'ca':
        contentJson = contentCa;
        LegalText = LegalCa;
        PrivacyText = PrivacyCa;

        break;
      default:
        contentJson = contentEn; // English is the default language
        LegalText = LegalEn;
        PrivacyText = PrivacyEn;


        break;
    }
    const openPrivacyModal = () => {
        setPrivacyModalOpen(true);
    };

    const closePrivacyModal = () => {
        setPrivacyModalOpen(false);
    };

    const openLegalModal = () => {
        setLegalModalOpen(true);
    };

    const closeLegalModal = () => {
        setLegalModalOpen(false);
    };

    const buttonStyle = {
        textDecoration: 'underline',
        color: 'black',
        '&:hover': {
            color: 'blue',
        },
        fontSize: '0.8em', // smaller font size for buttons
    };

    const boxStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '0.9em', // smaller font size for boxes
        marginTop: '20px',
    };

    const logoStyle = {
        width: '50px', // quarter of the original size
        height: '50px', // quarter of the original size
        borderRadius: '50%', // makes the image round
    };

    return (
        <div style={{ textAlign: 'center', marginBottom: '70px', marginTop: '20px' }}>
            <hr style={{ textAlign: 'center', marginBottom: '40px', marginTop: '10px' }} />

            <Flex direction={['column', 'row']}>
                <Box style={boxStyle}>
                    <Image src={logo} alt="Logo" style={logoStyle} />
                    thelab.cat
                </Box>
                <Spacer />
                <Box style={boxStyle}>
                    <button style={buttonStyle} onClick={openPrivacyModal}>{contentJson.privacyHeader}</button>
                    <br />
                    <button style={buttonStyle} onClick={openLegalModal}>{contentJson.legalHeader}</button>
                </Box>
                <Spacer />
                <Box style={boxStyle}></Box>
                <p style={{ fontSize: '0.8em' }}>
                    Instagram: <a href="https://www.instagram.com/thelab.cat/">@thelab.cat</a> <br />
                    Whatsapp: <a href="https://wa.me/34618378580">(+34) 618 37 85 80</a> <br />
                    Email: <a href="mailto:info@thelab.cat">info@thelab.cat</a>
                </p>            </Flex>

            <Modal isOpen={privacyModalOpen} onClose={closePrivacyModal} size="xl">
                <ModalOverlay />
                <ModalContent width="80%" height="80%" maxW="90vw" maxH="90vh">
                    <ModalHeader>{contentJson.privacyHeader}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody style={{ overflowY: 'auto', maxHeight: '80vh' }}>
                        {/* Add the text/content of your privacy policy here */}
                       <PrivacyText/>
                    </ModalBody>
                </ModalContent>
            </Modal>

            <Modal isOpen={legalModalOpen} onClose={closeLegalModal} size="xl">
                <ModalOverlay />
                <ModalContent width="80%" height="80%" maxW="90vw" maxH="90vh">
                    <ModalHeader>{contentJson.legalHeader}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody style={{ overflowY: 'auto', maxHeight: '80vh' }}>
                        {/* Add the text/content of your legal notice here */}
                        <LegalText/>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    );
}
