import '../css/App.css';
import Home from './Home.js';
import About from './About.js';

import FloatingGoHome from './FloatingGoHome.js';
import React, { useState, useEffect } from "react";
import CalculatorSection from './CalculatorSection.js';
import { Container} from '@chakra-ui/react';

import Portfolio from './Portfolio';
import FAQPage from './FAQPage';
import PageCTA from './PageCTA';
import Footer from './Footer';
import Navbar from './LanguageSelector';
const App = () => {
  const [currentSection, setCurrentSection] = useState("home");
  const [scrollPosition, setScrollPosition] = useState(0);

  // Use useEffect to listen for scroll events and determine the current section
  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
  
  
  useEffect(() => {
    const handleScroll = () => {
      const homeSection = document.getElementById("home");
      const calculatorSection = document.getElementById("calculator");
      const aboutSection = document.getElementById("about");

      const scrollY = window.scrollY;

      if (
        scrollY + 300 >= homeSection.offsetTop &&
        scrollY + 300 < calculatorSection.offsetTop
      ) {
        setCurrentSection("home");
      } else if (
        scrollY >= calculatorSection.offsetTop &&
        scrollY < aboutSection.offsetTop
      ) {
        setCurrentSection("calculator");
      } else {
        setCurrentSection("about");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <Container maxWidth={'full'} maxHeight={'full'}   height={'fit-content'} bgColor = {'white'}
      >   <Navbar/>
      <section id="home">
        <Home />
      </section>
      <section id="calculator">
        <div>
          {currentSection !== "home" && <FloatingGoHome />}
          <CalculatorSection/>
        </div>
      </section>
      <section id="portfolio">
        <div>
        
          {currentSection !== "home" && <FloatingGoHome />}
          <Portfolio/>
        </div>
       
      </section>
      <section id="about">
        <div>
          {currentSection !== "home" && <FloatingGoHome />}
          <About />
        </div>
       
      </section>
      <section id="FAQ">
        <div>
          {currentSection !== "home" && <FloatingGoHome />}
          <FAQPage/>
        </div>
       
      </section>
      <section id="CTA">
        <div>
          {currentSection !== "home" && <FloatingGoHome />}
          <PageCTA/>
        </div>
       
      </section>
      <Footer/>
       </Container>
    </div>
  );
};

export default App;
