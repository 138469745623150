import React from 'react';
import '../css/Home.css'
import myimage from '../images/image1.jfif'
import { useState, useEffect } from 'react';

import {
  Box,
  Heading,
  Container,
  Text,
  Button,
  Stack,
  Image,
  Flex,
  Spacer

} from '@chakra-ui/react'

import Contact from './Contact.js';

import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import contentEn from '../content/en/cta.json'; // Import English content
import contentEs from '../content/es/cta.json'; // Import Spanish content
import contentCa from '../content/ca/cta.json'; // Import Catalan content

import ContentTextCa from '../content/ca/cta.js';
import ContentTextEs from '../content/es/cta.js';
import ContentTextEn from '../content/en/cta.js';

export default function PageCTA() {
  const [shouldDisplayImage, setShouldDisplayImage] = useState(true);
  const { t, i18n } = useTranslation(); // Initialize the useTranslation hook

  let Content;
  let contentJson;
  // Determine which language is active
  switch (i18n.language) {
    case 'es':
      contentJson = contentEs;
      Content = ContentTextEs;
      break;
    case 'ca':
      contentJson = contentCa;
      Content = ContentTextCa;
      break;
    default:
      contentJson = contentEn; // English is the default language
      Content = ContentTextEn;
      break;
  }

  
  // Function to check the window width and set the display flag accordingly
  const handleWindowResize = () => {
    if (window.innerWidth <= 768) {
      setShouldDisplayImage(false);
    } else {
      setShouldDisplayImage(true);
    }
  };

  useEffect(() => {
    // Initial check on component mount
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      // Clean up the event listener on component unmount
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
        return (
        <Container maxWidth={'full'} bgColor={'white'} height={'fit-content'} maxHeight={'full'} 
        marginBottom={'200px'}>
             {/*Titol*/}
        <Heading
                fontWeight='extrabold'
                fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                lineHeight={'110%'}
               
                textAlign={'left'}
                >
                  
                  {contentJson.title}
          </Heading>
            <Stack
            direction={['column','row']}
         
          
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}>
            <Box   width={shouldDisplayImage ? '70%' : '100%'}
                alignItems={'left'}
                textAlign={'left'}
                >
                  <Flex direction={'column'} mt={-10} alignItems={'left'} spacing = {'20px'} textAlign = 'left'>
            
          <Spacer/>

             {/*Text */}
          <Text color={'black'} marginTop={'50px'} textAlign={'center'}
          fontSize={{ base: '1xl', sm: '1xl', md: '3xl' }}>
          <Content/>
            </Text>
            <Text color={'black'} marginTop={'50px'} textAlign={'center'}
          fontSize={{ base: '1xl', sm: '1xl', md: '3xl' }}>
            {contentJson.contact}
                </Text>
           <Contact/>

           <Box
            display={'inline-block'}
            mt={8}
            marginBottom = {'20px'}
            alignContent={'center'}
            textAlign={'center'}
            
            >
              
            <Button
              colorScheme={'gray'}
              bg={'gray.200'}
              rounded={'full'}
              px={6}
              onClick={() => {window.scrollTo({top: document.getElementById('calculator').offsetTop, behavior: 'smooth'});}}

              _hover={{
                bg: 'gray.300',
              }}>
              {contentJson.calculator}
            </Button>
            
            <Spacer/>
           
            
            </Box>
           
            </Flex>
            </Box>
             {  <Box width={shouldDisplayImage ? '30%' : '0%'}>
            {shouldDisplayImage &&<Flex direction={'column'}> <Image src={myimage} borderBottomRightRadius={'10px'} borderTopRightRadius={'10px'} sizes='100%' />

            {/*Imatges */}
              </Flex>}
            
              </Box>}
                
          
          

        </Stack>

        </Container>
      
        );
    

}


