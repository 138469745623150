
    
export  function calculatePrice(volume, material) { // volume in cm^3
    let density; //g/cm^3
    let priceMaterial;
    if (material === "PLA_BASIC") {
        density = 1.25;
        priceMaterial = 0.041;
    } else if (material === "ABS") {
        density = 1.02;
        priceMaterial = 0.045;
    } else if (material === "Nylon CF") {
        density = 1.08;
        priceMaterial = 0.065;
    } else if (material === "PLA_SILK") {
        density = 1.25;
        priceMaterial = 0.045;
    }
    else if (material === "PC") {
        density = 1.2;
        priceMaterial = 0.054;

    } else if (material === "TPU") {
        density = 1.04;
        priceMaterial = 0.057;
    } else if (material === "PETG") {
        density = 1.04;
        priceMaterial = 0.041;
    } else {
        density = 0;
        priceMaterial = 0;
    }
    let weight = density * volume;
    return priceMaterial * weight + 0.0036*weight + 0.03*weight + 2;

}
