import React from 'react';
import { Box,  Flex,  Text,  Link, IconButton, Container } from "@chakra-ui/react";
import { FaWhatsapp, FaInstagram, FaEnvelope } from 'react-icons/fa';
export default function Contact(){
    return(
    <Container maxWidth={'full'} height={'fit-content'}>
    <Box>
          
          <Flex direction={'row'} justifyContent={'center'}>
            <Link href="https://wa.me/34618378580" isExternal>
              <IconButton
              bgColor={'#d00000'}
              color = 'white'
              isRound={true}
                aria-label="WhatsApp"
                icon={<FaWhatsapp />}
                size="lg"
                mr={2}
              />
            </Link>
            <Link href="https://www.instagram.com/thelab.cat/" isExternal>
              <IconButton
                bgColor={'#d00000'}
                color = 'white'
              isRound={true}
                aria-label="Instagram"
                icon={<FaInstagram />}
                size="lg"
                mr={2}
              />
            </Link>
            <Link href="mailto:info@thelab.cat" isExternal>
              <IconButton
                bgColor={'#d00000'}
                color = 'white'
              isRound={true}
                aria-label="Email"
                icon={<FaEnvelope />}
                size="lg"
              />
            </Link>
          </Flex>
          </Box>
          </Container>
    );
}